import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import StickBar from "../components/StickBar";
import useScrollPosition from "../hooks/useScrollPosition";
import CircularProgress from "@mui/material/CircularProgress";

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const scroll = useScrollPosition();

  return (
    <div className="flex flex-col h-screen">
      <Header moreStyle="sticky bg-[#00263a]" logo={true} />

      <div className="flex">
        <StickBar moreStyle="sticky" />

        <div className="flex w-screen h-full items-center justify-center">
          <img src="/logo.png" className="w-[500px] " alt="" />
        </div>
      </div>
    </div>
  );
};

export default Home;
