import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import useAuth from "./hooks/useAuth";
import Modal from "@mui/material/Modal";
import { RxCross2 } from "react-icons/rx";
import { IoIosAlert } from "react-icons/io";

function App() {
  const {
    currentUser,
    setOpenVerifyMetamaskExtensionModal,
    openVerifyMetamaskExtensionModal,
    setOpenSwitchNetworkModal,
    openSwitchNetworkModal,
    SwitchNetwork,
    Logout,
    openErrorModal,
    setOpenErrorModal,
  } = useAuth();

  return (
    <>
      <Routes>
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/" element={<Home />} />
        <Route path="/profile" element={<Profile />} />
      </Routes>

      <Modal
        open={openVerifyMetamaskExtensionModal}
        onClose={() => {
          setIsLoading(false);
          setOpenVerifyMetamaskExtensionModal(false);
        }}
        className="flex items-center justify-center"
      >
        <div className="flex flex-col bg-white sm:rounded-xl w-[550px] h-full sm:h-[770px] outline-none relative space-y-20">
          <div className="">
            <div className="flex justify-end items-center p-6">
              <RxCross2
                className="w-11 h-11 cursor-pointer"
                onClick={() => {
                  setIsLoading(false);
                  setOpenVerifyMetamaskExtensionModal(false);
                }}
              />
            </div>

            <div className="flex justify-center">
              <img className="w-40" src="/logo-blue.png" alt="" />
            </div>
          </div>

          <div className="flex flex-col space-y-14  items-center">
            <div className="flex flex-col space-y-3 items-center">
              <IoIosAlert className="h-14 w-14 text-red-400" />

              <p className="text-[#00263a] text-[24px] font-black">
                No Metamask extensions detected
              </p>
            </div>

            <div className="flex flex-col space-y-8 items-center">
              <p className="text-[24px] text-center font-source">
                You need Metamask extension <br /> to continue browsing
              </p>

              <a
                className="flex bg-[#00263a] items-center font-source px-6 py-2 rounded-full cursor-pointer text-white"
                href="https://metamask.io/download/"
              >
                Install Metamask
                <img className="w-5 ml-2" src="/icon-metamask.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={openSwitchNetworkModal}
        onClose={() => {
          setOpenSwitchNetworkModal(false);
        }}
        className="flex items-center justify-center"
      >
        <div className="flex flex-col bg-white sm:rounded-xl w-[550px] h-full sm:h-[770px] outline-none relative space-y-20">
          <div className="">
            <div className="flex justify-end items-center p-6">
              <RxCross2
                className="w-11 h-11 cursor-pointer"
                onClick={() => {
                  setOpenSwitchNetworkModal(false);
                }}
              />
            </div>

            <div className="flex justify-center">
              <img className="w-40" src="/logo-blue.png" alt="" />
            </div>
          </div>

          <div className="flex flex-col space-y-14  items-center">
            <div className="flex flex-col space-y-3 items-center">
              <IoIosAlert className="h-14 w-14 text-red-400" />

              <p className="text-[#00263a] text-[24px] font-bold text-center">
                The Gallery <br /> only supported in Polygon
              </p>
            </div>

            <div className="flex flex-col space-y-3 items-center">
              <button
                className="flex bg-[#00263a] py-2 font-source w-[168px] justify-center rounded-full cursor-pointer text-white"
                onClick={SwitchNetwork}
              >
                Switch network
              </button>

              <button
                className="flex border font-source border-[#00263a] text-[#00263a] w-[168px] justify-center py-1.5 rounded-full cursor-pointer"
                onClick={() => {
                  Logout();
                  setOpenSwitchNetworkModal(false);
                }}
              >
                Disconnect wallet
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={openErrorModal}
        onClose={() => {
          setOpenErrorModal(false);
        }}
        className="flex items-center justify-center"
      >
        <div className="flex flex-col bg-white sm:rounded-xl w-[520px] h-full sm:h-[570px] outline-none relative space-y-20">
          <div className="">
            <div className="flex justify-end items-center p-6">
              <RxCross2
                className="w-11 h-11 cursor-pointer"
                onClick={() => {
                  setOpenErrorModal(false);
                }}
              />
            </div>

            <div className="flex justify-center">
              <img className="w-40" src="/logo-blue.png" alt="" />
            </div>
          </div>

          <div className="flex flex-col space-y-10 items-center text-center">
            <IoIosAlert className="h-14 w-14 text-[#FD866C]" />

            <div className="space-y-2 items-center text-[#00263a] w-[80%]">
              <p className="text-[24px] font-black">
                You are not authorized to access this platform or you are not
                logged in with the correct wallet.
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default App;
