import React, { useEffect, useState } from "react";
import { TbWallet } from "react-icons/tb";
import { AiOutlinePoweroff } from "react-icons/ai";
import useAuth from "../hooks/useAuth";
import { Link } from "react-router-dom";
import { isBrowser, isMobile } from "react-device-detect";

const Header = ({ moreStyle, logo }) => {
  const { currentUser, Logout, VerifyMetamaskExtension } = useAuth();

  return (
    <div
      className={
        "flex top-0 z-50 w-full px-4 h-[100px] justify-between items-center " +
        moreStyle
      }
    >
      <div className="">
        {logo && (
          <Link to="/">
            <img src="/logo.png" className="w-[180px] h-[50px]" alt="" />
          </Link>
        )}
      </div>

      <ul className="flex items-center space-x-4">
        {currentUser ? (
          <li>
            <Link to="/profile">
              <img
                src="/tg-profile.png"
                className="w-10 h-10 object-cover rounded-full cursor-pointer"
                alt=""
              />
            </Link>
          </li>
        ) : null}

        {currentUser ? (
          <li
            className="border-2 rounded-full p-2 cursor-pointer"
            onClick={Logout}
          >
            <AiOutlinePoweroff className="w-5 h-5 text-white" />
          </li>
        ) : (
          <li
            className="border-2 rounded-full p-2 cursor-pointer"
            onClick={VerifyMetamaskExtension}
          >
            <TbWallet className="w-5 h-5 text-white" />
          </li>
        )}

        <li>
          <div className="py-2 px-8 rounded-full font-semibold border-2 border-white text-white">
            {currentUser ? currentUser.walletAddress : "Adresse de l'artiste"}
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Header;
