import React from "react";
import { FaTwitterSquare, FaInstagramSquare, FaLinkedin } from "react-icons/fa";
import { HiDocumentText } from "react-icons/hi";

const StickBar = ({ moreStyle }) => {
  return (
    <div
      className={
        "flex flex-col top-[100px] left-0 z-50 h-[calc(100vh-100px)] justify-between px-[38px] py-8 space-y-10 overflow-auto scrollbar-hide " +
        moreStyle
      }
    >
      <div className="flex flex-col text-white space-y-10">
        <a
          className="rotate-180 text-lg"
          href="https://the-gallery.io/about-us"
          target="_blank"
          rel="noreferrer"
          style={{ writingMode: "vertical-rl" }}
        >
          About us
        </a>

        <a
          className="rotate-180 text-lg"
          href="https://the-gallery.io/terms-and-conditions"
          target="_blank"
          rel="noreferrer"
          style={{ writingMode: "vertical-rl" }}
        >
          Terms & Conditions
        </a>

        <span
          className="rotate-180 text-lg"
          style={{ writingMode: "vertical-rl" }}
        >
          © 2022 - The Gallery V1.0.0
        </span>
      </div>

      <div className="flex flex-col text-white space-y-2 items-center">
        <a
          href="https://twitter.com/the_galleryNFT"
          target="_blank"
          rel="noreferrer"
        >
          <FaTwitterSquare className="w-8 h-8" />
        </a>

        <a href="/" target="_blank" rel="noreferrer">
          <FaInstagramSquare className="w-8 h-8" />
        </a>

        <a
          href="https://www.linkedin.com/company/thenftgallery/"
          target="_blank"
          rel="noreferrer"
        >
          <FaLinkedin className="w-8 h-8" />
        </a>

        <a
          href="https://the-gallery-app.web.app/TG-WhitePaper.pdf"
          target="_blank"
          rel="noreferrer"
          className="flex bg-white rounded-sm  justify-center"
        >
          <HiDocumentText className="w-7 h-7 text-[#00263a]" />
        </a>
      </div>
    </div>
  );
};

export default StickBar;
