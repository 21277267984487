import React, { useState, useEffect, useRef } from "react";
import Header from "../components/Header";
import StickBar from "../components/StickBar";
import useScrollPosition from "../hooks/useScrollPosition";
import useAuth from "../hooks/useAuth";
import CircularProgress from "@mui/material/CircularProgress";
import {
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { mainnet, polygon, polygonMumbai } from "wagmi/chains";
import { ethers } from "ethers";
import abiPaymentSplitter from "../json/PaymentSplitter.json";
import Modal from "@mui/material/Modal";
import { AiOutlineClose, AiOutlineCheckCircle } from "react-icons/ai";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { RxCross2 } from "react-icons/rx";
import { IoIosAlert } from "react-icons/io";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";

const Profile = () => {
  const { currentUser, setOpenSwitchNetworkModal } = useAuth();
  const scrollPosition = useScrollPosition();
  const [accountBalance, setAccountBalance] = useState(null);
  const [isLoadingProgress, setIsLoadingProgress] = useState(true);
  const [openSuccessfulModal, setOpenSuccessfulModal] = useState(false);
  const [wagmiState, setWagmiState] = useState(false);
  const [paymentSplitterAddressSelected, setPaymentSplitterAddressSelected] =
    useState("");
  const [paymentSplitterAddressArray, setPaymentSplitterAddressArray] =
    useState([]);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openIsPendingModal, setOpenIsPendingModal] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (window.ethereum.chainId !== "0x13881") {
        setOpenSwitchNetworkModal(true);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const getArtisAddress = async () => {
      if (currentUser?.artistId === "The-Gallery") {
        const artistRef = collection(db, "artists");
        const q = query(artistRef, where("artistId", "!=", "The-Gallery"));
        const querySnapshot = await getDocs(q);

        let artisAddress = [];

        querySnapshot.forEach((doc) => {
          artisAddress.push({
            artistId: doc.data().artistId.replaceAll("-", " "),
            paymentSplitterAddress: doc.data().paymentSplitterAddress,
          });
        });

        setPaymentSplitterAddressArray(artisAddress);
        setPaymentSplitterAddressSelected(artisAddress[0]);
      } else {
        if (currentUser?.paymentSplitterAddress) {
          setPaymentSplitterAddressSelected({
            artistId: currentUser?.artistId.replaceAll("-", " "),
            paymentSplitterAddress: currentUser?.paymentSplitterAddress,
          });
        }
      }
    };

    if (currentUser) {
      getArtisAddress();
      setWagmiState(true);
    }
  }, [currentUser]);

  const contractRead = useContractRead({
    address: paymentSplitterAddressSelected?.paymentSplitterAddress,
    abi: abiPaymentSplitter,
    functionName: "releasable",
    chainId: polygonMumbai.id,
    args: [currentUser?.walletAddress],
    watch: true,
  });

  const { config } = usePrepareContractWrite({
    address: paymentSplitterAddressSelected?.paymentSplitterAddress,
    chainId: polygonMumbai.id,
    abi: abiPaymentSplitter,
    functionName: "release",
    args: [currentUser?.walletAddress],
    overrides: {
      gasLimit: 450000,
    },
  });

  const { data, error, write } = useContractWrite(config);

  const { isLoading, isSuccess } = useWaitForTransaction({
    chainId: polygonMumbai.id,
    hash: data?.hash,
    onSuccess(data) {
      setOpenIsPendingModal(false);
      setOpenSuccessfulModal(true);
      contractRead.data;
    },
    onError(error) {
      setOpenIsPendingModal(false);
      setOpenErrorModal(true);
      console.log("Error", error);
    },
  });

  useEffect(() => {
    setWagmiState(true);

    if (contractRead?.data) {
      setAccountBalance(ethers.utils.formatEther(contractRead?.data));
      setIsLoadingProgress(false);
    }
  }, [paymentSplitterAddressSelected, isSuccess, contractRead]);

  useEffect(() => {
    if (isLoading) {
      setOpenIsPendingModal(true);
    } else if (error) {
      if (error.code !== 4001) {
        setOpenErrorModal(true);
      }
    }
  }, [isLoading, error]);

  const handleChange = (event) => {
    setPaymentSplitterAddressSelected(event.target.value);
  };

  return (
    <>
      <Header
        moreStyle={
          scrollPosition > 50
            ? "sticky bg-[#00263a]"
            : "absolute bg-transparent"
        }
        logo={true}
      />

      <div className="flex">
        <StickBar moreStyle="fixed" />

        <div className="w-full space-y-14">
          <div className="w-full h-[500px]">
            <img
              className="object-cover w-full h-full"
              src="/tg-cover.png"
              alt=""
            />
          </div>

          <div className="pl-[140px] space-y-8 pr-4">
            <div className="w-full space-y-4">
              <p className="text-[54px] text-white">Your account balance </p>

              <div className="bg-white rounded-full h-2 w-full"></div>
            </div>

            {currentUser?.artistId === "The-Gallery" && (
              <FormControl sx={{ m: 1, minWidth: 550 }}>
                <InputLabel
                  id="demo-simple-select-autowidth-label"
                  sx={{ color: "white" }}
                >
                  Payment Splitter Address
                </InputLabel>

                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={paymentSplitterAddressSelected}
                  onChange={handleChange}
                  autoWidth
                  label="Payment Splitter Address"
                  sx={{
                    color: "white",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                    ".MuiSvgIcon-root ": {
                      fill: "white !important",
                    },
                  }}
                >
                  {paymentSplitterAddressArray.map((address, index) => (
                    <MenuItem key={index} value={address}>
                      {address?.artistId +
                        " - " +
                        address?.paymentSplitterAddress}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <div className="flex items-center justify-center h-[400px]">
              <div className="flex space-x-10 items-end">
                <p className="text-white text-[85px] leading-[60px]">
                  {accountBalance + " MATIC"}
                </p>

                <button
                  className=" py-2 px-6 bg-white text-lg text-[#00263A] rounded-full"
                  onClick={write}
                >
                  Retirer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={openSuccessfulModal}
        onClose={() => {
          setOpenSuccessfulModal(false);
        }}
        className="flex items-center justify-center"
      >
        <div className="flex flex-col bg-white rounded-xl w-[550px] h-[700px] outline-none">
          <div className="flex p-6 w-full justify-end">
            <div className="border-2 border-[#00263a] rounded-full p-1 cursor-pointer">
              <AiOutlineClose
                className="w-6 h-6 cursor-pointer"
                onClick={() => setOpenSuccessfulModal(false)}
              />
            </div>
          </div>

          <div className="flex flex-col space-y-20 text-[#00263a]">
            <div className="flex space-x-1 items-center justify-center">
              <img className="w-10" src="/favicon-black.png" alt="" />

              <div className="flex flex-col leading-[6px]">
                <span className="font-bold">The</span>
                <span className="text-xl font-bold text-[30px]">Gallery</span>
              </div>
            </div>

            <div className="flex flex-col items-center space-y-10">
              <AiOutlineCheckCircle className="w-12 h-12 text-[#33c7b6]" />

              <p className="text-[24px]">Funds sent successfully</p>

              <p className="text-[28px] font-bold text-center">
                Thank you for your collaboration !
              </p>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={openErrorModal}
        onClose={() => {
          setOpenErrorModal(false);
        }}
        className="flex items-center justify-center"
      >
        <div className="flex flex-col bg-white sm:rounded-xl w-[520px] h-full sm:h-[570px] outline-none relative space-y-20">
          <div className="">
            <div className="flex justify-end items-center p-6">
              <RxCross2
                className="w-11 h-11 cursor-pointer"
                onClick={() => {
                  setOpenErrorModal(false);
                }}
              />
            </div>

            <div className="flex justify-center">
              <img className="w-40" src="/logo-blue.png" alt="" />
            </div>
          </div>

          <div className="flex flex-col space-y-10 items-center text-center">
            <IoIosAlert className="h-14 w-14 text-[#FD866C]" />

            <div className="space-y-2 items-center text-[#00263a] ">
              <p className="text-[24px] font-black">An error has occurred.</p>

              <p className="text-[24px] font-black">
                Please try again later...
              </p>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={openIsPendingModal}
        onClose={() => {
          setOpenIsPendingModal(false);
        }}
        className="flex items-center justify-center"
      >
        <div className="flex flex-col bg-white sm:rounded-xl w-[520px] h-full sm:h-[570px] outline-none relative space-y-20">
          <div className="">
            <div className="flex justify-end items-center p-6">
              <RxCross2
                className="w-11 h-11 cursor-pointer"
                onClick={() => {
                  setOpenIsPendingModal(false);
                }}
              />
            </div>

            <div className="flex justify-center">
              <img className="w-40" src="/logo-blue.png" alt="" />
            </div>
          </div>

          <div className="flex flex-col space-y-20 items-center">
            <p className="text-[#00263a] text-[24px] font-bold">
              Transaction in progress, please wait.
            </p>

            <CircularProgress
              sx={{ color: "#33c7b6" }}
              style={{ height: 60, width: 60 }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Profile;
