import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "tg-stg-app.firebaseapp.com",
  projectId: "tg-stg-app",
  storageBucket: "tg-stg-app",
  messagingSenderId: "790614521911",
  appId: "1:790614521911:web:8ea4739045e391321d27b2",
  measurementId: "G-G3YZDP1MGD"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { app, db }